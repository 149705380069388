import React, { useMemo, useEffect ,useRef } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import '../../assets/css/table.css';
// import "datatables.net-jqui/js/dataTables.jqueryui.min.mjs"
// import $ from 'jquery';


function BasicTable({inputData,columnNames}) {
  //const tableRef = useRef(null);

    const columns = useMemo(() => columnNames, []);
    const data = useMemo(() => inputData, [])

    // Process data to lowercase keys
    const processedData = data.map(item =>
        Object.fromEntries(Object.entries(item).map(([key, value]) => [key.toLowerCase(), value]))
    );
   
  
    // Transform accessor names to lowercase
    const transformedColumns = React.useMemo(
        () =>
        columns.map((column) => ({
                ...column,
                accessor: row => row[column.accessor.toLowerCase()]
            })),
        [columns]
    );
  
    const tableInstance = useTable({
        columns: transformedColumns,
        data: processedData
    })

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow } = tableInstance;

        
  

    return (
        <Wrapper>
            {/* //ref={tableRef}  */}
            <table   {...getTableProps()}>
                <thead className='cust-thead'>
                    {
                        headerGroups.map((headerGroup) => (<tr {...headerGroup.getHeaderGroupProps()} >
                            {
                                headerGroup.headers.map((column)=>(
                                    <th  {...column.getHeaderProps()} >{ column.render('Header') }</th>
                                ))
                            }
                        </tr>))
                    }
                   
                </thead>
                <tbody {...getTableBodyProps()} >
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return(
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map((cell)=>{
                                            return <td {...cell.getCellProps()}
                                            style={{
                                                textAlign: cell.column?.center ? 'center' : 'left' // Align center if the cell.column.center property is true
                                              }}
                                             >
                                                {cell.render('Cell')}
                                                </td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
           
                </tbody>
            </table>
        </Wrapper>
    )
}

export default BasicTable;


const Wrapper = styled.div`
.cust-thead{
    position: sticky;
    top: 0;
    background-color: #f8f9fa; /* Background color for the fixed header */
}
`