import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/normalize.css';
import './index.css';
import App from './App';
import { LandingPage } from './pages';
import UserService from "./services/UserService";
import HttpService from "./services/HttpService";
import RenderOnAuthenticated from './services/RenderOnAuthenticated';
import RenderOnAnonymous from './services/RenderOnAnonymous'


const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = () => root.render(
  <>
    <RenderOnAuthenticated>
      <App />
    </RenderOnAuthenticated>
    <RenderOnAnonymous>
      <LandingPage />   
    </RenderOnAnonymous>
  </>
);

//below code renderApp without using keycloak authentication
//renderApp(); 

//below code renderApp without using keycloak authentication
UserService.initKeycloak(renderApp);
HttpService.configure();
