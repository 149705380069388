import React from 'react'
import styled from 'styled-components';

function ErrorPage({error}) {
  return (
    <Wrapper>
        <p> An Error Occured while loading the page</p>
        <p>{error.message}</p>
        <p>{error.stack}</p>
    </Wrapper>
  )
}

export default ErrorPage;

const Wrapper = styled.div`
`