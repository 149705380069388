
// CSS style for single valued react select dropdown list
const styleMenu1 = {
  menu: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '20px'
  })
}


export { styleMenu1 }