import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import styled from 'styled-components';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { Header, Page, Footer } from './components';
import { ToolsSelection, ToolAction, OptimiserResult, ProductionPlan, FurnaceDraw } from './pages';
import { AdminControl , ReportGeneration ,UserAccountControl } from './pages';
import UserService from './services/UserService';
import RenderOnRole from './services/RenderOnRole';
import AdminAction from './pages/admin/AdminAction';
import AdminToolsSelection from './pages/admin/AdminToolSelection';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}  >
      <Route element={<ToolsSelection />} path='/'></Route>
      <Route element={<ToolAction />} path='ToolAction'>
        <Route element={<OptimiserResult />} path='OptimiserResult'></Route>
        <Route element={<ProductionPlan />} path='ProductionPlan'></Route>
        <Route element={<FurnaceDraw />} path='FurnaceDraw'></Route>
      </Route>
    </Route>
  )
)

const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}  >
      <Route element={<AdminControl />} path='/'></Route>
      <Route element={<AdminAction />} path='AdminAction'>
        <Route element={<UserAccountControl />} path='UserAccountControl'></Route>
        <Route element={<ReportGeneration />} path='ReportGeneration'></Route>
        <Route element={<AdminToolsSelection />} path='AdminToolsSelection'></Route>
        <Route element={<OptimiserResult />} path='OptimiserResult'></Route>
        <Route element={<ProductionPlan />} path='ProductionPlan'></Route>
        <Route element={<FurnaceDraw />} path='FurnaceDraw'></Route>
      </Route>
    </Route>
  )
)


      {/* <RenderOnRole roles={['Admin']} >
      </RenderOnRole> */}
function AppLayout() {
  return (
    <>
    
      <Wrapper className="root">
   
        <Header />
        <Page />
        <Footer />
      </Wrapper>
    </>
  );

}

function App() {
  return (
    <>
        <ReactNotifications />
     <RouterProvider router={ UserService.hasRole(['adminrole'])?adminRouter:router } />
    </>
   
  );
}

export default App;

const Wrapper = styled.div`  
    display : flex;
    flex-direction: column;
    width:100%;
    min-height:100vh;
    padding:0px;
    margin:0px;
`