import HttpService from '../services/HttpService'

const ApiEnums = {
  runOptimiserEngine: "/runOptimiserEngine",
  optimiserOutput: "/displayOptimiserOutput",
  furnaceDraw: "/getPlanPerFurnaceDraw",
  productionPlan: "/getProductionPlanPerFurnace",
  masterdata : "/getMasterData",
  currentReportDetails : "/currentReportDetails",
  runOptimiserEngine : "/runOptimiserEngine",
  filterMachinesOnFurnaceAndScenarios : "/getAvailableMachines"

 }


async function postRequest(requestObject) {
  const axiosClient = HttpService.getAxiosClient();
  const responseObject = await axiosClient.post(requestObject?.url, requestObject?.data, requestObject?.config);
  if (responseObject?.status === 200 || responseObject?.status === 201) {
    return responseObject.data;
  }
  else {
    console.log('Error in DataRequestService.postRequest');
    console.log(responseObject)
    return 'error';
  }
}

function getRequest(requestObject) {
  const axiosClient = HttpService.getAxiosClient();
  let responseObject = axiosClient.get(requestObject.url, requestObject.config)
    .then(response => responseObject = response.data)
    .catch(error => {
      console.log('Error in DataRequestService.getRequest');
      console.error(error);
      return responseObject = 'error';
    });;

  return responseObject;
}

export { ApiEnums, postRequest, getRequest }