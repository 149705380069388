import React, { useEffect} from 'react'
import styled from 'styled-components';
import '../../assets/css/buttons/button71.css'; 
import { Link } from 'react-router-dom';

function AdminControl(){ 
    return (
        <Wrapper>
            <div className='box'>
                <div className="header">
                    <h1>Admin Controls</h1>
                </div>
                <div className="tools">
                    <ul>
                    <li>
                        {/* <Link to='AdminAction/UserAccountControl' state={'UserAccountControl'} className='button-71'>User Account Control</Link> */}
                        <a href={process.env.REACT_APP_KEYCLOAK_ADMIN_URL} target='_blank' className='button-71'>User Account Control</a>
                    
                    </li>
                    <li>
                        <Link to='AdminAction/ReportGeneration' state={'ReportGeneration'} className='button-71'>Report Generation</Link>
                    </li>
                    <li>
                        <Link to='AdminAction/AdminToolsSelection' state={'FurnaceDraw'} className='button-71'>Result View</Link>
                    </li>
                    </ul>
                </div>

            </div>
        </Wrapper>
    )
}

export default AdminControl;

const Wrapper = styled.div`
 .box{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
 }

 .tools a{
    margin : 0.5rem;
    width:100%;
    font-size:20px;
 }
`