import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import UserService from '../../services/UserService'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faSignIn, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { ClientLogo , CompanyLogo } from '../../assets/index'
import '../../assets/css/buttons/button39.css';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
     
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

  })

  const handlebtnLogout = () => {
     sessionStorage.clear();
     UserService.doLogout({ redirectUri: process.env.REACT_APP_PUBLIC_URL });
  }
 
  const handlebtnLogin = () => {
      const publicurl = process.env.REACT_APP_PUBLIC_URL;
      const url = new URL(publicurl);
      window.history.pushState({}, "", url);
      UserService.doLogin()
  }

  const handlebtnSignUp = () => {
    const publicurl = process.env.REACT_APP_PUBLIC_URL;
    const url = new URL(publicurl);
    window.history.pushState({}, "", url);
    UserService.doSignUp()
  }

  return (
    <Wrapper>     
        <header>
          <div className="nav-box-1">
        <a href="https://agiplastek.com/" target='_blank'>    <img src={ClientLogo} alt='agi-greenpac' />    </a>       
          </div>
          <div className="nav-box-2">
          {
              UserService.isLoggedIn() ?
                <>
                  <Button disabled className='button-39'>
                    <i><FontAwesomeIcon icon={faUser} /></i> &nbsp; {UserService.getUsername()}
                  </Button>
                  <p> </p>
                  <Button id="btnLogout" onClick={handlebtnLogout} className='button-39'>

                    Logout  &nbsp; <i><FontAwesomeIcon icon={faSignOutAlt} /></i>
                  </Button>
                </>
                :
                <>
                  <button id="btnLogin" onClick={handlebtnLogin} className='button-39' >
                    Login
                  </button>
                  <button id="btnSignUp" onClick={handlebtnSignUp} className="button-39">
                    Signup
                  </button>
                </>
            }
          </div>
        </header>    
    </Wrapper>
  )
}


const Wrapper = styled.div` 

header{
  display:flex;
  flex-direction : row;
  justify-content:space-between;
}

.nav-box-1{
  width:10rem;
  height:6rem;
  
  img{
     /* width: 100%; */
     height: 70%;
    margin: 10px;
  }
}

.nav-box-2{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  padding-right:2rem;
  button{
    height:3rem;
    margin:0.5rem;
    
  }

  div{
    display:flex;
    justify-content:center;
    align-items:center;
    padding-right :1rem;
    font-size:18px;
  }
  .user-name{
    p{
      font-size:18px;
    }
  }
  .company-logo{
    width:14rem;
    height:6rem;  
    img{
      width:100%;
      height:100%;
    }
  }
}
`

export default Header