import React, { useRef } from 'react';
import styled from 'styled-components';
import LoadingBar from 'react-top-loading-bar';
import {
  Outlet
} from 'react-router-dom'
import {ErrorBoundary} from './ErrorBoundary';
import { SiteBreadCrumb } from '../../components';
import { device } from '../../utils/device';

function Page(props) {
  const loadingBarRef = useRef(null)
  return (
    <Wrapper>
      <ErrorBoundary >
        <LoadingBar color='#ED6026' ref={loadingBarRef} />
        <div className="section-center">      
          <Outlet context={{ loadingBar: loadingBarRef }} />
        </div>
      </ErrorBoundary>
    </Wrapper>
  )
}

export default Page;


const Wrapper = styled.div`
  /* min-height: max(calc(100vh - 20vh),fit-content); */
  height: 100%;
  min-height: 85vh;
  /* border-top: 1px solid var(--clr-primary-1); */


 
  background-color: var(--clr-white);
  /* box-shadow: rgba(100,100,111,0.3) 0px 7px 29px 0px; */

  .err-text{
  font-size : 16px;
  color : red;
  margin-left : 10px;
}

  @media screen and (${device.tablet}) {
       
  }
  
  @media screen and (${device.mobileL}) {
    margin-top:8rem;
  }
`
