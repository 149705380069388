import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { MultiSelect } from "react-multi-select-component";
import '../assets/css/buttons/button44.css';
import TableComponent from '../components/common/BasicTable.js';
import { exportToExcel, getValue, setValue, convertTo2Precision , displayMessage , formatDateTimeString } from '../utils/helper.js'
import { ApiEnums, getRequest } from '../services/DataRequestService.js';
import { columnsOptimiserResult } from '../components/common/TableColumns';
import { ErrorPage } from '../components';
import { LoaderImage } from '../assets';

function OptimiserResult() {

  const [tabularData, setTabularData] = useState([]);


  const [furnacesOptions, setFurnacesOptions] = useState([]);
  const [machinesOptions, setMachinesOptions] = useState([]);
  
  const [furnaceSelected, setFurnaceSelected] = useState([]);
  const [machineSelected, setMachineSelected] = useState([]);

  const [showDownloadButton, setShowDownloadButton] = useState(false);


  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleChangeFurnace = (selected) => {
    //set the state varible of furnace
    setFurnaceSelected(selected);
    setShowDownloadButton(false);
    if (selected !== undefined) {

      //get values in required format to filter
      let valuesOfFurnace = getValuesFromOptions(selected);

      //master data
      let masterData = getValue('session', 'MasterData');
      let MachinesOfFurnace = masterData.filter(item => valuesOfFurnace.includes(item.Furnace)).flatMap(item => item.Machines).map(elem => ({ label: elem, value: elem }));

      setMachinesOptions(curr => curr = MachinesOfFurnace);
      setMachineSelected([]);
    }
  };

  const handleChangeMachine = (selected) => {
    setMachineSelected(selected);
    setShowDownloadButton(false);
  };



  const getValuesFromOptions = (arr) => {
    return arr.map(item => item.value);
  }

  const downloadOnClickHandler = () => {
    const downlaodableData = getValue('session', 'Optimiser');
    if (downlaodableData?.length > 0) {
      exportToExcel(downlaodableData, `OptimizerResult${furnaceSelected?.length == 1 ? '-' + furnaceSelected[0].value : ''}`, "Optimiser");
    }
    else {
      //alert('no data')
    }
  }

  useEffect(() => {
    const init = async () => {
      try {


        const requestObject = {
          "url": ApiEnums.masterdata
        }

        // bind the master data to controls
        const masterData = await getRequest(requestObject);
        setValue('session', 'MasterData', null, masterData);
        const furnacesOptions = masterData
          .map(value => ({ value: value.Furnace, label: value.Furnace }))
          .sort((a, b) => { return a.value.localeCompare(b.value); })
        const machinesOptions = masterData
          .flatMap(item => item.Machines.map(machine => ({ value: machine, label: machine })))
          .sort((a, b) => { return a.value.localeCompare(b.value); });
        setFurnacesOptions(arr => arr = furnacesOptions);        
      } catch (error) {
        setError({ error });
      }
    }
    init();


  }, [])

  function formatData(entityData) {

    const numericProperties = [
      "Stock_in_lakh_bottles_as_on_date",
      "Stock_in_MT_as_ondate",
      "AvgSales_last3months",     
      "Line_Efficiency",
      "Spillover_Allowance",
      "To_be_produced",
      "Opening_inventory",
      "MOQ",
      "Closing_inventory",
      "Time_prod",
      "Opt_prod"];
    const dateTimeProperties = [
    "Data_Date_x",
    "Data_Date_y"];
  
    entityData?.forEach(function (entry) {
      numericProperties.forEach(prop => {
        if (entry[prop] !== undefined) {
          entry[prop] = convertTo2Precision(entry[prop]);
        }
      });
  
      dateTimeProperties.forEach(prop => {
        if (entry[prop] !== undefined) {
          entry[prop] = formatDateTimeString(entry[prop]);
        }
      });
    });
  
    return entityData;
  }

  const onClickHandlerGenerateReport = async () => {
    setTabularData([]);
    if (furnaceSelected?.length === 0) {
      displayMessage('warning', 'Invalid Input', 'Please Select Furnace');
      return false;
    }
    if (machineSelected?.length === 0) {
      displayMessage('warning', 'Invalid Input', 'Please Select Machine');
      return false;
    }

    setLoading(true);
    let result = await getOptimiserResult();

    setLoading(false);
    if (result?.length === 0) {
      displayMessage('warning', 'No Data Found', 'No Data Found');
      return;
    }

     if (result !== 'error') {
       setShowDownloadButton(true);
       let valuesOfFurnace = getValuesFromOptions(furnaceSelected);
       let valuesOfMachine = getValuesFromOptions(machineSelected);

        let filteredResult = result.filter(item => valuesOfFurnace.includes(item['Furnace']) && valuesOfMachine.includes(item["Machine_No"]));

        setTabularData(filteredResult);
        setValue('session', 'Optimiser', null, filteredResult);
     } else {
        setTabularData([]);
        setValue('session', 'Optimiser', null, []);
        displayMessage('warning', 'No Data Found', 'No Data Found');
        return;
     }
  }

  async function getOptimiserResult() {
    let result = [];
    try {
      const reqObjOptimiser = {
        url: ApiEnums.optimiserOutput,
        config: {
          headers: {
            "Content-Type": "application/json"
          }
        }
      }
      result= await getRequest(reqObjOptimiser);    
      setValue('session', 'Optimiser', null, result);
    } catch (err) {
      return 'error';
    }

    return result;
  }

  if (error) {
    console.log(error);
    return <ErrorPage error={error.error} />
  }

  function renderTableSection(tabularData) {
    if (tabularData?.length === 0) {      
      return false;
    }
    return (<div>    
    <div className="table-container">
      <TableComponent inputData={formatData(tabularData)} columnNames={columnsOptimiserResult} />
    </div>
  </div>)
  }

  return (
    <Wrapper>
      <div className="box">
        <center><h1>Optimizer Result</h1></center>
        <div className="filter-section">

          <div className="filter-item">

            <div className='control'>
              <label>Furnace</label>
              <MultiSelect
                key="Furnace"
                options={furnacesOptions}
                onChange={handleChangeFurnace}
                value={furnaceSelected}
                className="custom-select"
                
              />

            </div>

            <div className="control">
              <label>Machines</label>
              <MultiSelect
                key="Machine"
                options={machinesOptions}
                onChange={handleChangeMachine}
                value={machineSelected}
                className="custom-select"
              />

            </div>

          </div>

          <div className="filter-button-controls">
            <div className='control'>
              <button id="btnGenerateReport" className='button-24 cust-btn' onClick={onClickHandlerGenerateReport} >Generate Report</button>
            </div>
     { showDownloadButton && <div className='control'>
              <button className='button-24 cust-btn' onClick={downloadOnClickHandler} >Download</button>
            </div>}
          </div>
        </div>

        { loading && <div className='center-align loader'>
              <img src={LoaderImage} width="100px" height="100px" />
              <br />
              <span> Fetching Optimizer Results... Please wait... </span>
            </div>}
        <div className="table-section">
          {tabularData && renderTableSection(tabularData)}
        </div>
      </div>
    </Wrapper>
  )
}

export default OptimiserResult

const Wrapper = styled.div`
padding-top:20px;


.box{
  display:flex;
  flex-direction:column;  
  height:fit-content;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07);
  min-height: 85vh;
}

.custom-select{
  width:400px;
}

.filter-section{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-bottom:1rem;

  div.filter-button-controls{
    margin:1rem;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    gap:2rem;
    .control{
      width:33%;
    }
  }

  div.filter-item{
    margin:1rem;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    gap:2rem
  }
  div.filter-item:last-child{
    align-items:center;
  }
  label{
    font-size:20px;
    padding-bottom:1rem;
  }
}

.download-section{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  margin-top:20px;
  margin-right:200px;
}

.cust-btn{
  width:12rem;
}

.table-container{
  width:99%;
  height:70vh;
  overflow:scroll;
  margin: 0 0.5rem;

}
.error-container , .table-container{
  margin: 0 0.5rem;

}
`