import React, { useEffect} from 'react'
import styled from 'styled-components';
import '../assets/css/buttons/button71.css';
import { Link } from 'react-router-dom';

function ToolsSelection() {
   
    return (
        <Wrapper>
            <div className='box'>
                <div className="header">
                    <h1>Tools Selection</h1>
                </div>
                <div className="tools">
                    <ul>
                    <li>
                        <Link to='ToolAction/OptimiserResult' state={'OptimiserResult'} className='button-71'>Optimizer Result</Link>
                    
                    </li>
                    <li>
                        <Link to='ToolAction/ProductionPlan' state={'ProductionPlan'} className='button-71'>Production Plan</Link>
                    </li>
                    <li>
                        <Link to='ToolAction/FurnaceDraw' state={'FurnaceDraw'} className='button-71'>Furnace Draw</Link>
                    </li>
                    </ul>
                </div>

            </div>
        </Wrapper>
    )
}

export default ToolsSelection;


const Wrapper = styled.div`
 .box{
    display:flex;
    flex-direction:column;
    align-items:center;
    height:100%;
    min-height: 85vh;
 }

 .tools a{
    margin : 0.5rem;
    width:100%;
    font-size:20px;
 }
`