import React, { useState, useEffect , useRef } from 'react'
import styled from 'styled-components'
import { LoaderImage } from '../../assets';
import '../../assets/css/buttons/button44.css';
import { ApiEnums, getRequest } from '../../services/DataRequestService';
import { displayMessage } from '../../utils/helper';

async function apiCallforReportGeneration() {
  let result = null;
  const requestObject = {
    url: ApiEnums.runOptimiserEngine,
    config: {
      headers: {
        "Content-Type": "application/json"
      }
    }
  }
  result = await getRequest(requestObject);
  return result;
}

async function apiCallforReportInitialisation() {
  let result = null;
  const requestObject = {
    url: ApiEnums.currentReportDetails,
    config: {
      headers: {
        "Content-Type": "application/json"
      }
    }
  }
  result = await getRequest(requestObject);
  return result;
}

//async function apiCallfor

export default function ReportGeneration() {
  const [loading, setLoading] = useState(false);
  let buttonRef = useRef(0);
  let repGenDate = useRef('');
  let repgenStatus = useRef('');

  useEffect(() => {
    const init = async () => {
      let result = await apiCallforReportInitialisation();
      console.log(result)
      if(result !== 'error'){
        repGenDate.current.value = result?.GeneratedOn;
        repgenStatus.current.value = result?.Status;
      }
    }
    init();
  }, []);

  const OnClickHandlerReportGeneration = async () => {

    buttonRef.current.disabled = true;
    buttonRef.current.innerText = "Report Generating..."; 
    setLoading(true);
    let result = await apiCallforReportGeneration();
    if (result !== 'error') {
      repGenDate.current.value = result?.GeneratedOn;
      repgenStatus.current.value = result?.Status;
      displayMessage('success', 'Success', 'Report generated successfully');
    } else {
      displayMessage('warning', 'Error occured', 'please check console log');
    }
    setLoading(false);
   
    buttonRef.current.disabled = false;
    buttonRef.current.innerText = "Report Generation"; 
  }



  return (
    <Wrapper>
      <div className="box">
        <center><h1>Report Generation</h1></center>
        <div className="report-form">
          <div className="repo-item repo-control">
            <label htmlFor="" className='repo-text'>Last report generated on:</label>
            <input ref={repGenDate} type="text" name="report-generation-date" id="report-generation-date" disabled />
          </div>
          <div className="repo-item repo-control">
            <label htmlFor="" className='repo-text'>Last report generation status :</label>
            <input ref={repgenStatus} type="text" name="report-generation-status" id="report-generation-status" disabled />
          </div>
          <div className="repo-item">
            <p className='repo-text' >New report will be generated in the current folder of the storage space and old records will be available in the archive folder. If you want to generate a new report click on report generation.</p>
          </div>
          <div className="repo-item">
            <button ref={buttonRef} className='button-24 cust-btn' onClick={OnClickHandlerReportGeneration} >Report Generation</button>
          </div>
          <div className="repo-item">
           { loading && <div className='loader'>
              <img src={LoaderImage} width="100px" height="100px" />
              <br />
              <span> processing... please wait... </span>
            </div>}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}


const Wrapper = styled.div`
padding-top:20px;

.box{
  display:flex;
  flex-direction:column;  
  height:fit-content;
  //box-shadow: 0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.report-form .repo-control{
  display:flex;
  flex-direction: row;
}

.repo-control{
  
 justify-content:flex-start;
}

.repo-item{
  label{
    width : 40rem;
  }
  input{
    width:30rem;
    padding:0.5rem; 
  }
  label, input {
    font-size:24px;
    margin:0.5rem;
  }
 
  p.repo-text{
      font-size:24px;
      font-weight:bold;

  }
  text-align:center;
  margin:1rem;
}

.cust-btn{
  width:fit-content;
  font-size:24px;
  padding:1.5rem;
  height:auto;
}
`