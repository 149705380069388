import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select'
import '../assets/css/buttons/button44.css';
import TableComponent from '../components/common/BasicTable.js';
import { getValue, setValue, convertTo2Precision, isObjectEmpty, displayMessage , formatDateTimeString, formatScenarioString } from '../utils/helper.js';
import { ScenarioOptions } from '../utils/staticData.js'
import { ApiEnums, getRequest, postRequest } from '../services/DataRequestService.js';
import { columnsProductionPlan } from '../components/common/TableColumns';
import * as XLSX from 'xlsx';
import { styleMenu1 } from './scripts/shared.js';
import { LoaderImage } from '../assets';



function ProductionPlan() {

  const [tabularData, setTabularData] = useState([]);

  const [furnacesOptions, setFurnacesOptions] = useState([]);
  const [machinesOptions, setMachinesOptions] = useState([]);
  const [scenarioOptions, setScenarioOptions] = useState(ScenarioOptions);

  const [furnaceSelected, setFurnaceSelected] = useState({});
  const [machineSelected, setMachineSelected] = useState([]);
  const [scenarioSelected, setScenarioSelected] = useState([]);

  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const init = async () => {

      const requestObject = {
        "url": ApiEnums.masterdata
      }
      // bind the master data to controls
      const masterData = await getRequest(requestObject);
      setValue('session', 'MasterData', null, masterData);



      const furnacesOptions = masterData
        .map(value => ({ value: value.Furnace, label: value.Furnace }))
        .sort((a, b) => { return a.value.localeCompare(b.value); });
    //  const machinesOptions = masterData.flatMap(item => item.Machines.map(machine => ({ value: machine, label: machine })));
      setFurnacesOptions(arr => arr = furnacesOptions);
   //   setMachinesOptions(arr => arr = machinesOptions);
      setScenarioOptions(ScenarioOptions)

    }
    init();
  }, [])

  const getValuesFromOptions = (arr) => {
    return arr.map(item => item.value);
  }

  const filterMachinesOnFurnaceAndScenarios = async (_furnance, _scenarios) => {
    let result = [];
    //     
    try {
      const requestObject = {
        url: ApiEnums.filterMachinesOnFurnaceAndScenarios,
        config: {
          headers: {
            "Content-Type": "application/json"
          },
          params: {
            furnace: _furnance
          }
        },
        data: _scenarios
      }
      let responseData = await postRequest(requestObject);
      let availableMachines = [...new Set(responseData?.data?.flatMap(item => item.Machines))].filter(item => item !== undefined);
      console.log(availableMachines);
   
      let machineOptions = availableMachines.map(e => ({ value : e, label : e}))
      machineOptions.sort((a, b) => { return a.value.localeCompare(b.value); });
      setMachinesOptions(machineOptions);
    } catch (err) {
      console.log(err);
      return 'error';
    }
    return result;
  }


  const handleChangeFurnace = async (selected) => {
    try {
      setShowDownloadButton(false);
      setTabularData([]);

      // set the state varible of furnace
      setFurnaceSelected(selected);
      if (selected !== undefined) {
        //get values in required format to filter
        let valuesOfFurnace = selected?.value;
        if(valuesOfFurnace && scenarioSelected?.length > 0){
          let scenariosValue = scenarioSelected.map(elem => elem.value);
          await filterMachinesOnFurnaceAndScenarios(valuesOfFurnace,scenariosValue);      
        }else{
          setMachinesOptions([]);
        }        
      }
    } catch (err) {
      throw err
    }
  };



  const handleChangeMachine = (selected) => {
    setMachineSelected(selected);
    setShowDownloadButton(false);
    setTabularData([]);
  };

  const handleChangeScenario = async (selected) => {
    setShowDownloadButton(false);
    setTabularData([]);
    let selectedCount = selected?.length;
    const selectedValues = selected.map(item => item.value);
    let scenarioOpt = ScenarioOptions;
    switch (selectedCount) {
      case 2:
        scenarioOpt.forEach(option => {
          if (!selectedValues.includes(option.value)) {
            option.disabled = true;
          }
        });
        setScenarioOptions(scenarioOpt);
        break;
      case 1:
      case 0:
        scenarioOpt.forEach(option => {
          option.disabled = false;
        });
        setScenarioOptions(scenarioOpt);
        break;
      default:
        console.log(`logical error in handleChangeScenario selectedCOunt :${selectedCount} `)
        break;
    }
    setScenarioSelected(selected);
    if(selected?.length > 0 &&  !(Object.keys(furnaceSelected).length === 0 && furnaceSelected.constructor === Object)){
      let furnaceValue = furnaceSelected.value;
      let scenariosValue = selected.map(e => e.value);
      await filterMachinesOnFurnaceAndScenarios(furnaceValue,scenariosValue); 
    }else{
      setMachinesOptions([]);
    }  
  };


  const downloadOnClickHandler = () => {

    const downlaodableData = getValue('session', 'ProductionPlan');
    if (downlaodableData?.length > 0) {
      exportToExcelMultiSheet(downlaodableData, `ProductionPlanForFurnace-${furnaceSelected?.value}`);
    }
    else {
      console.log('No data found for download')
    }
  }

  function exportToExcelMultiSheet(tabData, fileName) {
    const workbook = XLSX.utils.book_new();
    for (let dataItem of tabData) {
      if(dataItem?.data !== null){
      let worksheet = XLSX.utils.json_to_sheet(dataItem.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, dataItem.scenario);
      }
    }
    // Get the sheet names
    const sheetNames = workbook.SheetNames;

    if (sheetNames.length > 0) {
          XLSX.writeFile(workbook, fileName + '.xlsx');
    } else {
      displayMessage('warning', 'No Content', 'No Content to download');
    }
  }

  const onClickHandlerGenerateReport = async () => {
    if (isObjectEmpty(furnaceSelected)) {
      displayMessage('warning', 'Invalid Input', 'Please Select Furnace');
      return false;
    }
    if (machineSelected?.length === 0) {
      displayMessage('warning', 'Invalid Input', 'Please Select Machine');
      return false;
    }
    if (scenarioSelected?.length === 0) {
      displayMessage('warning', 'Invalid Input', 'Please Select Scenario');
      return false;
    }

    setLoading(true);
    let result = await getProductionPlanData();
    setLoading(false);

    if (result?.length === 0) {
      displayMessage('warning', 'No Data Found', 'No Data Found');
      return;
    }

    if (result !== 'error') {
      setShowDownloadButton(true);
      let valuesOfMachine = getValuesFromOptions(machineSelected);
      //let filteredResult = result.f(item => valuesOfMachine.includes(item["Machine No"]));
      const filteredData = result.map(obj => ({
        scenario: obj?.scenario,
        //data: obj?.data?.filter(item => valuesOfMachine.includes(item["Machine No"]))
        data: obj.data !== null?obj.data.filter(item => valuesOfMachine.includes(item["Machine No"])) : null
      }));
      setTabularData(filteredData);
      setValue('session', 'ProductionPlan', null, filteredData);
    } else {
      setTabularData([]);
      setValue('session', 'ProductionPlan', null, []);
      displayMessage('warning', 'No Data Found', 'No Data Found');
      return;
    }
  }

  async function getProductionPlanData() {
    let result = [];
    //     
    try {
      let arrScenarios = scenarioSelected?.map(item => item.value);
      const reqDataProductionPlan = {
        url: ApiEnums.productionPlan,
        config: {
          headers: {
            "Content-Type": "application/json"
          },
          params: {
            furnace: furnaceSelected.value
          }
        },
        data: arrScenarios
      }
      result = await postRequest(reqDataProductionPlan);
    } catch (err) {
      return 'error';
    }

    return result;
  }

  



  // Main function to format the data
function formatData(entityData) {

  const numericProperties = [
    "Stk in lakh bottles as on date",
    "Running weight",
    "Need to produce",

  ];
  const dateTimeProperties = [ "Job change start",  "Start date",  "end date"];

  entityData?.forEach(function (entry) {
    numericProperties.forEach(prop => {
      if (entry[prop] !== undefined) {
        entry[prop] = convertTo2Precision(entry[prop]);
      }
    });

    dateTimeProperties.forEach(prop => {
      if (entry[prop] !== undefined) {
        entry[prop] = formatDateTimeString(entry[prop]);
      }
    });
  });

  return entityData;
}

  function renderTableSection(tabularData) {
    if (tabularData?.length === 0) {
      return false;
    }
    // return tabularData.map((tdata) => (
    //   <div>
    //   <p>{ formatScenarioString(tdata.scenario)}</p>
    //   <div className="table-container">
    //     <TableComponent inputData={formatData(tdata?.data)} columnNames={columnsProductionPlan} />
    //   </div>
    // </div>))
    return tabularData.map((tdata,index) => (
      ( tdata['scenario'] !== null && tdata.data !== null ) ? <div key={index}>
      <p>{ formatScenarioString(tdata.scenario)}</p>
      <div className="table-container">
        <TableComponent inputData={formatData(tdata?.data)} columnNames={columnsProductionPlan} />
      </div>
    </div> :<p key={index} className='err-text' >Production Plan report not found for scenario { tdata.scenario}</p>
    ))

  }



  return (
    <Wrapper>

      <div className="box">
        <center><h1>Production plan</h1></center>
        <div className="filter-section">
          <div className="drp3">
            <div className='control'>
              <label>Furnace</label>

              <Select
                key="Furnace"
                options={furnacesOptions}
                onChange={handleChangeFurnace}
                value={furnaceSelected}
                className="custom-select"
                styles={styleMenu1}
              />
            </div>
            <div className='control'>
              <label>Scenario</label>
              <MultiSelect
                key="Scenario1"
                options={scenarioOptions}
                onChange={handleChangeScenario}
                value={scenarioSelected}
                hasSelectAll={false}
                className="custom-select"
              />
            </div>
            <div className="control">
              <label>Machines</label>
              <MultiSelect
                key="Machine"
                options={machinesOptions}
                onChange={handleChangeMachine}
                value={machineSelected}
                className="custom-select"
              />
            </div>
           
          </div>
          <div className="filter-button-controls">
            <div className='control'>
              <button id="btnGenerateReport" className='button-24 cust-btn' onClick={onClickHandlerGenerateReport} >Generate Report</button>
            </div>
            {showDownloadButton && <div className='control'>
              <button id="btnDownload" className='button-24 cust-btn' onClick={downloadOnClickHandler} >Download</button>
            </div>}
          </div>
        </div>
        { loading && <div className='center-align loader'>
              <img src={LoaderImage} width="100px" height="100px" />
              <br />
              <span> Fetching Production Plan... Please wait... </span>
            </div>}
        <div className="table-section">
          {tabularData && renderTableSection(tabularData)}
        </div>
      </div>
    </Wrapper>
  )
}

export default ProductionPlan;

const Wrapper = styled.div`
padding-top:20px;

.box{
  display:flex;
  flex-direction:column;  
  height:fit-content;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07);
  min-height: 85vh;
}

.custom-select{
  width:400px;
}


.filter-section{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-bottom:1rem;
  width:100%;

  div.filter-button-controls{
    margin:1rem;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    gap:2rem;
    .control{
      width:33%;
    }
  }

.drp3{
  display:grid;
  margin:1rem;
  row-gap:1rem;
  grid-template-columns:auto auto;
  width:75%;
}
  div.filter-item:last-child{
    align-items:center;
  }
  label{
    font-size:20px;
  }
}

.download-section{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  margin-top:20px;
  margin-right:200px;
}

.cust-btn{
  width:12rem;
}

.table-container{
  width:99%;
  height:30vh;
  overflow:scroll;
  margin: 0 0.5rem;
  margin-bottom : 2rem;

}
.error-container , .table-section{
  margin: 0 0.5rem;
}

.table-section > div > p{
    padding-left : 0.5rem;
    font-size: 20px;
    padding-bottom: 1rem;
    font-family: Roboto, Arial, sans-serif;
    background: var(--clr-white);
    color: #04AA6D;
    line-height: 1.5;
    font-weight:bold;
    text-transform: capitalize;
}

.err-text{
  font-size : 16px;
  color : red;
  margin-left : 10px;
}
`