import React from 'react'
import styled from 'styled-components'
import UserService from '../services/UserService'
import ClientLogo from '../assets/client-logo.png'
import '../assets/css/buttons/button39.css';

 function LandingPage() {

    const handlebtnLogin = () => {
        const publicurl = process.env.REACT_APP_PUBLIC_URL;
        const url = new URL(publicurl);
        window.history.pushState({}, "", url);
        UserService.doLogin()
    }
  
    const handlebtnSignUp = () => {
      const publicurl = process.env.REACT_APP_PUBLIC_URL;
      const url = new URL(publicurl);
      window.history.pushState({}, "", url);
      UserService.doSignUp()
    }


    return (
        <Wrapper>
            <div className="box">
                <div className="agi-greenpac-logo">

                </div>
                <p className='title'>PPC</p>
                <button id="btnLogin" onClick={handlebtnLogin} className='button-39' >
                    Login
                </button>
                <button id="btnSignUp" onClick={handlebtnSignUp} className="button-39">
                    Signup
                </button>
            </div>
        </Wrapper>
    )
}

export default LandingPage;

const Wrapper = styled.div`
 background-color : #F15E22;
 height:100vh;
 display:flex;
 justify-content:center;
    align-items:center;
.box{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding : 5rem;
    width:fit-content;
    height:fit-content;
    background : white; 
    border-radius : 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11);
}

#btnLogin , #btnSignUp {
    padding: 1rem 4rem;
    font-size : 18px;
    width : fit-content;
    margin : 0.5em 0;
}

.agi-greenpac-logo{
    width: 300px;
    height: 200px;
    background-image: url(${ClientLogo});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.title{
    font-size:20px;
    margin:1rem;
}

`