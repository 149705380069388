const columnsOptimiserResult = [
    {
        "Header": "Item_code",
        "accessor": "Item_code"
    },
    {
        "Header": "Item_Description",
        "accessor": "Item_Description"
    },
    {
        "Header": "Colour",
        "accessor": "Colour"
    },
    {
        "Header": "Segment",
        "accessor": "Segment"
    },
    {
        "Header": "Sub_Seg",
        "accessor": "Sub_Seg"
    },
    {
        "Header": "Weight",
        "accessor": "weight",
        center: true
    },
    {
        "Header": "Stock_in_lakh_bottles_as_on_date",
        "accessor": "Stock_in_lakh_bottles_as_on_date",
        center: true
    },
    {
        "Header": "Stock_in_MT_as_ondate",
        "accessor": "Stock_in_MT_as_ondate",
        center: true
    },
    {
        "Header": "Single_Source",
        "accessor": "Single_Source"
    },
    {
        "Header": "Min_Quantity",
        "accessor": "Min_Quantity",
        center: true
    },
    {
        "Header": "Max_Quantity",
        "accessor": "Max_Quantity",
        center: true
    },
    {
        "Header": "N_month_sales",
        "accessor": "N_month_sales",
        center: true
    },
    {
        "Header": "N+1_month_sales",
        "accessor": "N+1_month_sales",
        center: true
    },
    {
        "Header": "N+2_month_sales",
        "accessor": "N+2_month_sales",
        center: true
    },
    {
        "Header": "N+3_month_sales",
        "accessor": "N+3_month_sales",
        center: true
    },
    {
        "Header": "REALISATION",
        "accessor": "REALISATION",
        center: true
    },
    {
        "Header": "Delivery_Date",
        "accessor": "Delivery_Date"
    },
    {
        "Header": "AvgSales_last3months",
        "accessor": "AvgSales_last3months",
        center: true
    },
    {
        "Header": "Furnace",
        "accessor": "Furnace"
    },
    {
        "Header": "Machine_No",
        "accessor": "Machine_No"
    },
    {
        "Header": "Running_weight",
        "accessor": "Running_weight",
        center: true
    },
    {
        "Header": "Remarks",
        "accessor": "Remarks"
    },
    {
        "Header": "Line_Efficiency",
        "accessor": "Line_Efficiency",
        center: true
    },
    {
        "Header": "Line_Capacity",
        "accessor": "Line_Capacity",
        center: true
    },
    {
        "Header": "Spillover_Allowance",
        "accessor": "Spillover_Allowance",
        center: true
    },
    {
        "Header": "Total_demand",
        "accessor": "Total_demand",
        center: true
    },
    {
        "Header": "To_be_produced",
        "accessor": "To_be_produced",
        center: true
    },
    {
        "Header": "Opening_inventory",
        "accessor": "Opening_inventory",
        center: true
    },
    {
        "Header": "MOQ",
        "accessor": "MOQ",
        center: true
    },
    {
        "Header": "Closing_inventory",
        "accessor": "Closing_inventory",
        center: true
    },
    {
        "Header": "Time_prod",
        "accessor": "Time_prod",
        center: true
    },
    {
        "Header": "Opt_prod",
        "accessor": "Opt_prod",
        center: true
    },
    {
        "Header": "Scenario",
        "accessor": "Scenario"
    }
]



const columnsProductionPlan = [
    {
        Header: 'Furnace',
        accessor: 'Furnace'
    },
    {
        Header: 'Machine No',
        accessor: 'Machine No'
    },
    {
        Header: 'Job Change Start',
        accessor: 'Job change start'
    },
    {
        Header: 'Start date',
        accessor: 'Start date'
    },
    {
        Header: 'End Date',
        accessor: 'end date'
    },
    {
        Header: 'Item Description',
        accessor: 'Item Description'
    },
    {
        Header: 'Item code',
        accessor: 'Item code'
    },
    {
        Header: 'Process',
        accessor: 'Process'
    },
    {
        Header: 'Single Source',
        accessor: 'Single Source'
    },
    {
        Header: 'Stk in lakh bottles as on date',
        accessor: 'Stk in lakh bottles as on date',
        center: true
    },
    {
        Header: 'Total Demand',
        accessor: 'Total demand',
        center: true
    },    
    {
        Header: 'Running Weight',
        accessor: 'Running weight',
        center: true
    },
    {
        Header: 'Need to Produce Lakhs',
        accessor: 'Need to produce',
        center: true
    },
    {
        Header: 'bpm',
        accessor: 'bpm',
        center: true
    },
    {
        Header: 'Days',
        accessor: 'Days',
        center: true
    },
    {
        Header: 'Per Day Production (Lakhs)',
        accessor: 'Per day production',
        center: true
    },
    {
        Header: 'Sections allocated',
        accessor: 'Sections allocated',
        center: true
    },
    {
        Header: 'Gob details',
        accessor: 'Gob details',
        center: true
    },    
    {
        Header: 'Draw(MT/day)',
        accessor: 'Draw(MT/day)',
        center: true
    },    
    {
        Header: 'Remarks',
        accessor: 'Remarks'
    }
];

const columnsFurnaceDraw = [   
    {
        Header: 'job start date',
        accessor: 'Job start date'
    },
    {
        Header: 'WeekDay',
        accessor: 'WeekDay'
    },
    {
        Header: 'Planned Draw(MT/day)',
        accessor: 'Planned Draw(MT/day)',
        center: true
    },
    {
        Header: 'Actual Draw(MT/day)',
        accessor: 'Actual Draw(MT/day)',
        center: true
    },
    {
        Header: 'Machines',
        accessor: 'Machines'
    }
]

const columnsKPIMetrics = [
    {
        Header: 'Metric',
        accessor: 'Metric'
    },
    {
        Header: 'Value',
        accessor: 'Value'
    }
]
export { columnsOptimiserResult , 
            columnsProductionPlan , 
            columnsFurnaceDraw , 
            columnsKPIMetrics }


//  NOTE : when you make changes to column names here please remember to make changes in formatdata function in respective pages