import * as XLSX from 'xlsx';
import { Store } from 'react-notifications-component';

function exportToExcel(jsonData, fileName, sheetName) {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, fileName + '.xlsx');
}



/*
storageType : 'local' | 'session'
storageObjectName : string

*/
function setValue(storageType, storageObjectName, propertyName, value) {
  let storageObjectStr = null;
  if (storageType.toLowerCase() === 'local') {
    storageObjectStr = localStorage.getItem(storageObjectName);
  } else if (storageType.toLowerCase() === 'session') {
    storageObjectStr = sessionStorage.getItem(storageObjectName);
  }
  let storeObject = null;
  if (storageObjectStr !== null) {
    storeObject = JSON.parse(storageObjectStr);
    if (propertyName && storeObject) {
      storeObject[propertyName] = value;
    } else if (!propertyName && storeObject) {
      storeObject = value;
    } else {
      return false; // when propertyName is defined but not the storageObjectName
    }
  } else {
    storeObject = {};
    if (propertyName && storageObjectName) {
      storeObject[propertyName] = value;
    } else if (!propertyName && storeObject) {
      storeObject = value;
    } else {
      return false; // when propertyName is defined but not the storageObjectName
    }
  }

  if (storageType.toLowerCase() === 'local') {
    localStorage.setItem(storageObjectName, JSON.stringify(storeObject));
  } else if (storageType.toLowerCase() === 'session') {
    sessionStorage.setItem(storageObjectName, JSON.stringify(storeObject));
  }
  return true;
}

function getValue(storageType, storageObjectName, propertyName) {
  let storageObjectStr = null;
  if (storageType.toLowerCase() === 'local') {
    storageObjectStr = localStorage.getItem(storageObjectName);
  } else if (storageType.toLowerCase() === 'session') {
    storageObjectStr = sessionStorage.getItem(storageObjectName);
  }
  if (storageObjectStr !== null) {
    let storeObject = JSON.parse(storageObjectStr); //https://stackoverflow.com/questions/42494823/json-parse-returns-string-instead-of-object
    if (propertyName && storeObject.hasOwnProperty(propertyName)) {
      return storeObject[propertyName];
    } else {
      return storeObject;
    }
  }
  else {
    return false;
  }
}

function displayMessage(type, title, message) {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "bottom-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 1000,
      onScreen: false
    }
  });
}

function formatDateTimeString(sampleDate) {

  //  this is expected format for sampleDate = "2023-11-30T00:00:00";
const date = new Date(sampleDate);

const day = ("0" + date.getDate()).slice(-2);
const month = ("0" + (date.getMonth() + 1)).slice(-2);
const year = date.getFullYear();
const hours = ("0" + date.getHours()).slice(-2);
const minutes = ("0" + date.getMinutes()).slice(-2);
const seconds = ("0" + date.getSeconds()).slice(-2);

const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

return formattedDate; // Output: 30-11-2023 00:00:00


}

const convertTo2Precision = (elem) => {
  return isNaN(parseFloat(elem)) ? '' : parseFloat(elem).toFixed(2);
 }

 const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

function formatScenarioString(str){
  return str?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export { exportToExcel, setValue, getValue, formatDateTimeString, convertTo2Precision, displayMessage , isObjectEmpty , formatScenarioString }